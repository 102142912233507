import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [{
  path: '',
  pathMatch: 'full',
  redirectTo: '/dashboard'
}, {
  path: 'login',
  loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
}, {
  path: 'sign-up',
  loadChildren: () => import('./modules/sign-up/sign-up.module').then(m => m.SignUpModule)
}, {
  path: 'forgot-password',
  loadChildren: () => import('./modules/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
}, {
  path: 'verify-otp',
  loadChildren: () => import('./modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
}, {
  path: 'verify-email',
  loadChildren: () => import('./modules/email-verify/email-verify.module').then(m => m.EmailVerifyModule)
}, {
  path: 'v',
  loadChildren: () => import('./modules/preview-template/preview-template.module').then(m => m.PreviewTemplateModule)
}, {
  path: '',
  component: HomeComponent,
  canActivate: [AuthGuard],
  children: [
    {
      path: 'dashboard',
      canActivate: [AuthGuard],
      loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
    }, {
      path: 'analytics',
      canActivate: [AuthGuard],
      loadChildren: () => import('./modules/analytics/analytics.module').then(m => m.AnalyticsModule)
    }, {
      path: 'user-profile',
      canActivate: [AuthGuard],
      loadChildren: () => import('./modules/user-profile/user-profile.module').then(m => m.UserProfileModule)
    }, {
      path: 'settings',
      canActivate: [AuthGuard],
      loadChildren: () => import('./modules/setting/setting.module').then(m => m.SettingModule)
    },{
      path: 'administration',
      canActivate: [AuthGuard],
      loadChildren: () => import('./modules/administration/administration.module').then(m => m.AdministrationModule)
    }, { path: '**', redirectTo: 'dashboard' },
  ]
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }