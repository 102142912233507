import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.shouldIntercept(req)) {
      if (this.isOidcRequest(req)) {
        return next.handle(req);
      } else {
        req = this.modifyRequest(req);
        return this.handleRequest(req, next);
      }
    } else {
      return next.handle(req);
    }
  }

  private shouldIntercept(req: HttpRequest<any>): boolean {
    
    return ['POST', 'GET', 'PUT', 'DELETE'].includes(req.method);
  }

  private isOidcRequest(req: HttpRequest<any>): boolean {
    // Exclude OIDC URLs from modification
    const oidcUrls = [
      'https://accounts.google.com',
      'https://www.googleapis.com',
      'https://openidconnect.googleapis.com',
      'https://oauth2.googleapis.com',
      'https://www.googleapis.com/oauth2/v3/userinfo',
      'https://www.googleapis.com/oauth2/v4/token'
    ];
    
    const isOidc = oidcUrls.some(url => req.url.includes(url));
    return isOidc;
  }

  private modifyRequest(req: HttpRequest<any>): HttpRequest<any> {
    req = req.clone({ url: environment.apiUrl + req.url });

    if (
      !req.url.includes('login') &&
      !req.url.includes('qr/') &&
      !req.url.includes('forgot') &&
      !req.url.includes('token/validate') &&
      !req.url.includes('reset') &&
      !req.url.includes('email/resend') &&
      !req.url.includes('email/verify') &&
      !req.url.includes('activate') &&
      this.authService.accessToken
    ) {
      return req.clone({
        headers: req.headers.set(
          'Authorization',
          `Bearer ${this.authService.accessToken}`
        ),
      });
    }

    return req;
  }

  private handleRequest(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (event) => {},
        (err) => {
          if (this.isUnauthorized(err) && !req.url.includes('login')) {
            this.handleUnauthorizedRequest();
          }
        }
      )
    );
  }

  private isUnauthorized(err: any): boolean {
    return err.status === 401 || err.status === 403;
  }

  private handleUnauthorizedRequest() {
    this.authService.clearToken();
    this.router.navigate(['/login'], {
      queryParams: { returnUrl: this.router.url },
    });
  }
}
