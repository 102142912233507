<div>
  <nav class="font-poppins-regular bg-gray-800 z-50 w-full top-[0px] fixed">
    <div class="mx-auto max-w-10xl px-2 sm:px-6 lg:px-8">
      <div class="relative flex h-16 items-center">
        <div class="cursor-pointer" routerLink="dashboard" (click)="setActiveTab('generateQR')">
          <img style="height: 54px" src="assets/images/zehnme-large-logo.svg" />
        </div>
        <div class="flex flex-1 flex-row-reverse">
          <div class="hidden sm:block">
            <div class="flex space-x-4">
              <a [ngClass]="{ 'bg-gray-900': activeTab === 'dashboard' }" routerLink="dashboard"
                (click)="setActiveTab('dashboard')"
                class="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium">Dashboard</a>
            </div>
          </div>
        </div>
        <div class="absolute inset-y-0 right-0 flex gap-x-4 items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <!-- Profile dropdown -->
          <div class="relative ml-3">
            <div>
              <button (click)="openUserMenu()" type="button"
                class="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                <span class="absolute -inset-1.5"></span>
                <span class="sr-only">Open user menu</span>
                <div *ngIf="profile_image" >
                  <img [src]="profile_image" alt="Profile-image"
                    class="w-[35px] h-[35px] rounded-full ring-2 ring-gray-300">
                </div>
                <div *ngIf="!profile_image" class="bg-white w-[35px] h-[35px] rounded-full ring-2 ring-gray-300 flex justify-center items-center">
                  <p class="font-semibold text-[15px] text-gray-900">{{firstChar}}</p>
                </div>
              </button>
            </div>
            <div *ngIf="userMenu" id="user-menu"
              class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
              <a routerLink="user-profile" (click)="userMenu = !userMenu"
                class="block px-4 py-2 cursor-pointer text-sm text-gray-700 hover:text-white hover:bg-[#2e475d]" role="menuitem"
                tabindex="-1" id="user-menu-item-0">Account Details</a>
              <a routerLink="analytics" (click)="userMenu = !userMenu"
                class="block px-4 py-2 cursor-pointer text-sm text-gray-700 hover:text-white hover:bg-[#2e475d]" role="menuitem"
                tabindex="-1" id="user-menu-item-0">Analytics</a>
              <a routerLink="settings" (click)="userMenu = !userMenu"
                class="block px-4 py-2 cursor-pointer text-sm text-gray-700 hover:text-white hover:bg-[#2e475d]" role="menuitem"
                tabindex="-1" id="user-menu-item-0">Settings</a>
              <a *ngIf="admin" routerLink="administration" (click)="userMenu = !userMenu"
                class="block px-4 py-2 cursor-pointer text-sm text-gray-700 hover:text-white hover:bg-[#2e475d]" role="menuitem"
                tabindex="-1" id="user-menu-item-0">Administration</a>
              <a (click)="logout()" class="block px-4 py-2 cursor-pointer text-sm text-gray-700 hover:text-white hover:bg-[#2e475d]"
                role="menuitem" tabindex="-1" id="user-menu-item-2">Sign Out</a>
            </div>
          </div>
          <!-- toggle menu  -->
          <button class="toggle-btn" (click)="openMenu(!isOpen)">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" h-8 w-8 class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" stroke="#fff"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!-- Mobile menu, show/hide based on menu state. -->
    <div class="sm:hidden overflow-hidden" id="mobile-menu">
      <div class="space-y-1 px-2 pb-3 pt-2 mobile-menu" [ngClass]="isOpen ? 'menu-open' : 'menu-close'">
        <ul>
          <!-- <li>
            <a [ngClass]="{ 'bg-gray-900': activeTab === 'analytics' }" routerLink="analytics"
              class="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
              (click)="setActiveTab('analytics')">Analytics</a>
          </li> -->
          <li>
            <a [ngClass]="{ 'bg-gray-900': activeTab === 'generateQR' }" routerLink="dashboard"
              (click)="setActiveTab('generateQR')"
              class="bg-gray-900 text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium">Dashboard</a>
          </li>
          <li *ngIf="admin">
            <a [ngClass]="{ 'bg-gray-900': activeTab === 'administration' }" routerLink="administration"
              (click)="setActiveTab('administration')"
              class="bg-gray-900 text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium">Administration</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
<div class="mt-16">
<router-outlet></router-outlet>
</div>
<footer class="bg-gray-800 z-40 p-1 flex text-white justify-center items-center fixed w-full bottom-[0px]">
  <div>
    <p class="text-[#dbdbdb]">Copyright © {{currentYear}}<a class="text-[#6dd4dc] ml-2 mr-2" href="https://www.zehn.me"
      target="_blank">Zehn.me</a>All rights reserved.<p>
  </div>
</footer>