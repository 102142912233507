import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedUserService {
  private triggerAnalyticsData = new Subject<string>();
  triggerAnalyticsData$ = this.triggerAnalyticsData.asObservable();
  triggerResetData = new Subject<string>();
  triggerResetData$ = this.triggerResetData.asObservable();
  private selectedImageSubject = new Subject<string | ArrayBuffer | null>();
  private selectedBgSubject = new Subject<string | ArrayBuffer | null>();
  private selectedLogoSubject = new Subject<string | ArrayBuffer | null>();
  private selectedVideoSubject = new Subject<string | ArrayBuffer | null>();
  private selectedOtherImagesSubject = new Subject<[] | ArrayBuffer | 'null'>();
  private uploadedUserProfileSubject = new Subject<string | ArrayBuffer | null>();
  private userProfileNameSubject = new Subject<string | ArrayBuffer | null>();
  private clickSource = new Subject<any>();
  click$ = this.clickSource.asObservable();

  emitClickEvent(func?: any) {
    this.clickSource.next(func ? func : true);
  }

  triggerAnalytics(variable: string) {
    this.triggerAnalyticsData.next(variable);
  }

  getTriggerAnalytics(): Observable<string> {
    return this.triggerAnalyticsData;
  }

  setTriggerResetData(variable: string) {
    this.triggerResetData.next(variable);
  }

  getTriggerResetData(): Observable<string> {
    return this.triggerResetData;
  }

  setProfileSelectedImage(imageData: string | ArrayBuffer | null) {
    this.selectedImageSubject.next(imageData);
  }

  getProfileSelectedImage() {
    return this.selectedImageSubject.asObservable();
  }

  setBgSelectedImage(imageData: string | ArrayBuffer | null) {
    this.selectedBgSubject.next(imageData);
  }

  getBgSelectedImage() {
    return this.selectedBgSubject.asObservable();
  }

  setLogoSelectedImage(imageData: string | ArrayBuffer | null) {
    this.selectedLogoSubject.next(imageData);
  }

  getLogoSelectedImage() {
    return this.selectedLogoSubject.asObservable();
  }

  setSelectedVideo(videoData: string | ArrayBuffer | null) {
    this.selectedVideoSubject.next(videoData);
  }

  getSelectedVideo() {
    return this.selectedVideoSubject.asObservable();
  }

  setOtherImagesSelectedImage(imageData: any | null) {
    if (imageData !== null) {
      this.selectedOtherImagesSubject.next(imageData);
    }
  }

  getOtherImagesSelectedImage() {
    return this.selectedOtherImagesSubject.asObservable();
  }

  defaultStyle(dData: any, tempFormData: any){
    tempFormData.themeColor = dData.card_data.themeColor;
    tempFormData.backgroundColor = dData.card_data.backgroundColor;
    tempFormData.cardColor = dData.card_data.cardColor;
    tempFormData.textColor = dData.card_data.textColor;
    tempFormData.highlightedColor = dData.card_data.highlightedColor;
    tempFormData.iconColor = dData.card_data.iconColor;
  }

  setUserProfileImage(imageData: any | null){
    this.uploadedUserProfileSubject.next(imageData);
  }

  getUserProfileImage(){
    return this.uploadedUserProfileSubject.asObservable();
  }

  setUserProfileName(data: any | null){
    this.userProfileNameSubject.next(data);
  }

  getUserProfileName(){
    return this.userProfileNameSubject.asObservable();
  }

  settingUpdate(sData: any, cardData: any, tempFormData: any){
    if (sData?.is_name_as_template_name === 1) {
      tempFormData.firstName = cardData?.first_name;
      tempFormData.lastName = cardData?.last_name;
    }
    if (sData?.is_email_as_template_email === 1) {
      tempFormData.email = cardData?.email;
    }
    if (sData?.is_phone_as_template_phone_no === 1 && cardData?.mobile) {
      tempFormData.phone = cardData?.mobile;
      tempFormData.phonePrefix = cardData?.PhonePrefix;
    }
    if (sData?.is_sms_as_template_sms_no === 1 && cardData?.mobile) {
      tempFormData.sms = cardData?.mobile;
      tempFormData.smsPrefix = cardData?.PhonePrefix;
    }
  }

  hasThreeValues(tempFormData: any): boolean {
    const socialMedia = [
      tempFormData?.whatsapp,
      tempFormData?.facebook,
      tempFormData?.instagram,
      tempFormData?.linkedin,
      tempFormData?.twitter
    ];

    // Filter to count truthy values
    const filledFieldsCount = socialMedia.filter(field => !!field).length;

    // Return true if 3 or more fields are filled
    return filledFieldsCount >= 3;
  }
}
